{
    const DEFAULT_TYPE = "string";

    class aflVaultPageController {

        static get $inject() {
            return ['VaultDataItemTypeConstant', 'VaultService'];
        }

        constructor(VaultDataItemTypeConstant, VaultService) {
            this.VaultService = VaultService;
            this.VaultDataItemTypeConstant = VaultDataItemTypeConstant;
            this.addedFilterCriteria = [];

            this.type = DEFAULT_TYPE;

            this.resetCursors();

            this.getItemsByFilter({
                params: {
                    type: this.type
                },
                page: 0
            });

        }

        selectItem(item) {
            this.selectedItem = item;
        }

        resetCursors() {
            this.cursors = [];
            delete this.currentPage;
            delete this.lastPage;
        }

        setPageData({cursor, results}, page) {
            this.pages[page] = {
                cursor,
                items: results.map(item => new VaultDataItem(item))
            };
        }

        addCursor(cursor, page) {
            if (!page) {
                this.cursors.push(cursor);
            } else {
                this.cursors[page] = cursor;
            }
        }

        getCursorForPage(page) {
            return this.cursors[page - 1];
        }

        markAsLastPage() {
            this.lastPage = this.currentPage;
        }

        getItemsByFilter({params, filter, page}) {
            return this.VaultService.getItems(params, filter)
                .then(results => {

                    if (results && results.data && results.data.results) {
                        this.items = results.data.results
                            .map(item => new VaultDataItem(item));

                        this.currentPage = page ? page : 0;

                        if (results.data.cursor) {
                            this.addCursor(results.data.cursor, page);
                        } else {
                            this.markAsLastPage();
                        }

                        this.selectedItem = this.items[0];
                    }

                });
        }

        prepareRequest(items) {
            return items
                .filter(item => item.operator)
                .map(function (item) {

                    const filterValue = item.itemType === VaultDataItemTypeConstant.DATE
                        ? new Date(item.value).getTime() : item.value;

                    return {
                        fieldName: item.fieldName,
                        filterValue,
                        operator: item.operator.VALUE
                    };
                });
        }

        onUpdateFilterItems(items, page) {
            this.resetCursors();
            this.getItemsByFilter({
                params: {
                    type: this.type
                },
                filter: this.prepareRequest(items),
                page
            });
        }

        onUpdateType(type) {
            this.resetCursors();
            this.getItemsByFilter({
                params: {
                    type
                },
                filter: this.prepareRequest(this.addedFilterCriteria),
                page: 0
            })
        }

        onFirstPage() {
            this.getItemsByFilter({
                params: {
                    type: this.type,
                },
                filter: this.prepareRequest(this.addedFilterCriteria),
                page: 0
            });
        }

        onNextPage() {
            if (this.getCursorForPage(this.currentPage + 1)) {
                this.getItemsByFilter({
                    params: {
                        type: this.type,
                        cursor: this.getCursorForPage(this.currentPage + 1)
                    },
                    filter: this.prepareRequest(this.addedFilterCriteria),
                    page: this.currentPage + 1
                });
            }
        }

        onPrevPage() {
            if (this.getCursorForPage(this.currentPage - 1)) {
                this.getItemsByFilter({
                    params: {
                        type: this.type,
                        cursor: this.getCursorForPage(this.currentPage - 1)
                    },
                    filter: this.prepareRequest(this.addedFilterCriteria),
                    page: this.currentPage - 1
                });
            } else {
                this.getItemsByFilter({
                    params: {
                        type: this.type
                    },
                    filter: this.prepareRequest(this.addedFilterCriteria),
                    page: 0
                });
            }
        }

        onLastPage() {
            if (this.lastPage !== undefined && this.getCursorForPage(this.lastPage)) {
                this.getItemsByFilter({
                    params: {
                        type: this.type,
                        cursor: this.getCursorForPage(this.lastPage)
                    },
                    filter: this.prepareRequest(this.addedFilterCriteria),
                    page: this.lastPage
                })
                    .then(() => {
                        this.currentPage = this.lastPage;
                    });
            }
        }
    }

    angular.module('adminApp')
        .component('aflVaultPage', {
            templateUrl: '/static/javascript/admin/afl-vault/afl-vault-page/afl-vault-page.html',
            controller: aflVaultPageController
        });

}
